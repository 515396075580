import React, { useMemo } from 'react'
import { graphql, Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { SendEnquiryBlock } from '/src/components/common'

import './certificates.scss'

export const Head = () => {
  return <title>Certificates | Metalcorp</title>
}

const ResourceContent = ({ body }) => {
  return body.map(({ processed }, index) => {
    return (
      <div key={index} className="body mb-2" dangerouslySetInnerHTML={{
        __html: processed
      }}></div>
    )
  })
}

const CertificateListItemNoLink = ({ title, body }) => {
  return (
    <Link className="certificate-list-item">
      <div className="title-container">
        <h5 className="title">{title}</h5>
      </div>
      <ResourceContent body={body} />
    </Link>
  )
}

// @todo, link / pdf icon.
const CertificateListItem = ({ title, body, file }) => {
  if (!file || !file.length) return <CertificateListItemNoLink title={title} body={body} />

  return (
    <a className="certificate-list-item" target="_blank" href={file[0].uri.url}>
      <div className="title-container">
        <h5 className="title">{title}</h5>
      </div>
      <ResourceContent body={body} />
    </a>
  )
}

const CertificateList = ({ items }) => {

  return (
    <div className="certificate-list">
      <Row>
        {items.map((item, index) => {
          return (
            <Col key={index} className="mb-4" sm={12}>
              <CertificateListItem {...item} />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

const CertificateGroupList = ({ resources }) => {
  const groups = useMemo(() => {
    const results = {}

    for (let index = 0; index < resources.length; index++) {
      const { node } = resources[index]
      const [category] = node.relationships.field_resource_category
  
      if (category && category.name) {
        if (!results.hasOwnProperty(category.name)) {
          results[category.name] = [{
            title: node.title,
            body: node.body,
            file: node.relationships.field_resource_file
          }]
        }
        else {
          results[category.name].push({
            title: node.title,
            body: node.body,
            file: node.relationships.field_resource_file
          })
        }
      }
    }

    return results
  }, [])

  return (
    <div className="certificate-group-list">
      <Row>
        {Object.entries(groups).map(([name, value], index) => {
          return (
            <div key={index} className="certificate-group mb-5">
              <Row>
                <Col sm={4}>
                  <div className="title-container">
                    <h3 className="title">{name}</h3>
                  </div>
                </Col>
                <Col sm={8}>
                  <CertificateList items={value} />
                </Col>
              </Row>
            </div>
          )
        })}
      </Row>
    </div>
  )
}

export default ({ data }) => {
  const resources = data.allNodeResources.edges

  return (
    <Layout>
      <Row className="mt-5 mb-5">
        <Col xs={12}>
          <div className="mb-5">
            <Link className="back-to-link" to='/resources'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to resources
            </Link>
          </div>

          <CertificateGroupList resources={resources} />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12}>
          <SendEnquiryBlock />
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
{
  allNodeResources(
    filter: {relationships: {field_resource_category: {elemMatch: {name: {in: [
      "NATA", 
      "General", 
      "Active Fire Protection"
    ]}}}}}
    sort: {fields: created, order: DESC}
  ) {
    edges {
      node {
        body {
          processed
        }
        path {
          alias
        }
        title
        relationships {
          node__product {
            title
            path {
              alias
            }
          }
          field_resource_file {
            uri {
              url
            }
          }
          field_resource_category {
            name
          }
        }
      }
    }
  }
}
`

/*export const query = graphql`
{
  allNodeResources(
    filter: {relationships: {field_resource_category: {elemMatch: {name: {in: [
      "NATA", 
      "General", 
      "Active Fire Protection"
    ]}}}}}
    sort: {fields: created, order: DESC}
  ) {
    edges {
      node {
        body {
          processed
        }
        path {
          alias
        }
        title
        relationships {
          node__product {
            title
            path {
              alias
            }
          }
          field_resource_file {
            publicUrl
          }
          field_resource_cover {
            gatsbyImage(
              fit: COVER
              formats: AUTO
              layout: FULL_WIDTH
              quality: 100
              placeholder: BLURRED
              width: 315
              height: 225
            )
          }
          field_resource_category {
            name
          }
        }
      }
    }
  }
}
`*/
